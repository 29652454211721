<template>
   <v-alert dense outlined type="warning" v-if="showMessage" 
    icon="mdi mdi-alert-outline" border="left" 
    class="mb-2">
    {{message}}
  </v-alert>
</template>

<script>
export default {
  name: 'DeductibleWarning',
  data() {
    return {
      showMessage: false,
    }
  },
  props: {
    deductibleControlName: String,
    deductibleType: String,
    module: String
  },
  methods: {
    checkDeductible() {
      const value = this.$store.getters.getControlValue(this.module,this.deductibleControlName)

      if (!value) this.showMessage = false;

      if (value && value.toLowerCase() === 'aop') this.showMessage = false;

      const percentage = parseFloat(value)
      const allPerilDeductible = this.$store.getters.getControlValue(this.module,'AllPerilDeductible')

      // If all peril deductible is a percentage, compare it directly with the wind peril percentage
      if (allPerilDeductible && (allPerilDeductible + '').indexOf('%') !== -1) {
        this.showMessage = percentage <= parseFloat(allPerilDeductible);
      }

      const coverageForm = this.$store.getters.getControlValue(this.module,'CoverageOption');
      // RENTERS ONLY get Coverage C since Coverage A does not exist
      const coverageTotal = (coverageForm == 'ho4' || coverageForm == 'ho6')
        ? this.$store.getters.getControlValue(this.module,'CovC') + this.$store.getters.getControlValue(this.module,'CovA')
        : this.$store.getters.getControlValue(this.module,'CovA');
      // The value is calculated as a percentage of coverage
      this.showMessage = coverageTotal * (percentage / 100) <= parseFloat(allPerilDeductible);

    }
  },
  computed: {
    message() { 
      return 'The ' + this.deductibleType + ' deductible should be equal to or greater than the AOP deductible.';
    }
  },
  mounted() {
    this.$store.watch(() => {
      this.checkDeductible();
    }, () => {},
    {
      immediate: true
    })
  }
}
</script>
